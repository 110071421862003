import { RadioOption } from "../../../components/RadioOption";

export const SharedDedicated = ({
  selected,
  setSelected,
}: {
  selected: "shared" | "dedicated";
  setSelected: React.Dispatch<React.SetStateAction<"shared" | "dedicated">>;
}) => {
  return (
    <div className="mt-[2.375rem]">
      <h2 className="text-white text-xs font-medium font-[Roboto] mb-[0.625rem]">
        Datacenter Mode or ISP Mode
      </h2>
      <div className="space-y-3">
        <RadioOption
          selected={selected === "shared"}
          label="Datacenter Mode"
          description="Datacenter mode, for high-quality upstream quality"
          onClick={() => setSelected("shared")}
        />
        <RadioOption
          selected={selected === "dedicated"}
          label="ISP Mode"
          description="ISP mode, for tunneled premium-quality upstream"
          onClick={() => setSelected("dedicated")}
        />
      </div>
    </div>
  );
};
