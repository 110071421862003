import { useState } from "react";
import { RadioOption } from "../../../components/RadioOption";

export const ProxyOption = () => {
  const [selected, setSelected] = useState<"residential" | "isp">("isp");

  return (
    <div>
      <h2 className="text-white text-xs font-medium font-[Roboto] mb-[0.625rem]">
        Proxy Option
      </h2>
      <div className="flex items-center gap-[1.875rem] w-full max-sm:flex-col max-sm:gap-[0.625rem]">
        <RadioOption
          selected={selected === "residential"}
          label="Private Residentials"
          description={
            <span>
              Residentials fully owned.{" "}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    "https://docs.roundproxies.com/en/articles/10214914-private-residentials-basics",
                    "_blank"
                  );
                }}
                className="border-b border-dashed border-[#ffffff50]"
              >
                Learn more.
              </span>
            </span>
          }
        />
        <RadioOption
          selected={selected === "isp"}
          label="ISP or Datacenter Proxies"
          description={
            <span>
              ISPs with custom mode.{" "}
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(
                    "https://docs.roundproxies.com/en/articles/10214917-isp-proxies-basics",
                    "_blank"
                  );
                }}
                className="border-b border-dashed border-[#ffffff50]"
              >
                Learn more.
              </span>
            </span>
          }
          badge="NEW"
          onClick={() => setSelected("isp")}
        />
      </div>
    </div>
  );
};
