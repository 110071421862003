import { useState, useEffect, useRef } from "react";
import Checkout from "./components/Checkout";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { PricingData, ProxyCount } from "./components/ProxyCount";
import { ProxyOption } from "./components/ProxyOption";
import RegionSelector from "./components/RegionSelector";
import { SharedDedicated } from "./components/SharedDedicated";
import { UsageOptions } from "./components/UsageOptions";
import { UsageOption } from "../../types/purchase";
import { useApi } from "../../api/api";
import WarningOverlay from "./components/WarningOverlay";

export interface SubscriptionData {
  _id: string;
  userId: string;
  regions: Record<string, number>;
  type: "shared" | "dedicated";
  ipCount: number;
  startDate: string;
  endDate: string;
  subscriptionId: string;
  priceId: string;
  usage: number;
  usageOption: "unlimited" | "limited";
  pendingChanges: any;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface RegionData {
  name: string;
  type: string;
  totalIps: number;
  totalIpsUsed: number;
  availability: number;
}

export default function Purchase() {
  // State variables
  const [selected, setSelected] = useState<"shared" | "dedicated">("shared");
  const [priceId, setPriceId] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [usageOption, setUsageOption] = useState<UsageOption>(
    UsageOption.UNLIMITED
  );
  const [count, setCount] = useState("");
  const [hasDistributionError, setHasDistributionError] = useState(false);
  const api = useApi();

  const [regions, setRegions] = useState<RegionData[]>([]);
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionData | null>(null);
  const initialValuesSet = useRef(false);
  const [regionInputValues, setRegionInputValues] = useState<
    Record<string, string>
  >({});
  const [regionDistribution, setRegionDistribution] = useState<
    Record<string, string>
  >({});
  const [regionErrors, setRegionErrors] = useState<Record<string, string>>({});
  const [regionGlobalError, setRegionGlobalError] = useState<string>("");
  const [focusedRegion, setFocusedRegion] = useState<string | null>(null);
  const [pricingData, setPricingData] = useState<PricingData>({
    shared: [],
    dedicated: [],
  });
  const [unlimitedDataPricingPerMonth, setUnlimitedDataPricingPerMonth] =
    useState<number>(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const firstPrice = useRef<number>(null);
  const [isEditing, setIsEditing] = useState(false);

  const warning = isEditing && (firstPrice.current || 0) > totalPrice;

  // Fetch subscription data
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await api.get("/subscription");
        const data: SubscriptionData = response.data;
        setIsEditing(!!data);
        setSelected(data.type);
        setUsageOption(
          data.usageOption === "unlimited"
            ? UsageOption.UNLIMITED
            : UsageOption.LIMITED
        );
        setCount(data.ipCount.toString());
        setQuantity(data.ipCount);

        if (data.priceId) {
          setPriceId(data.priceId);
        }

        setSubscriptionData(data);
      } catch (error) {
        console.error("Failed to fetch subscription data:", error);
        setSubscriptionData(null);
      }
    };

    fetchSubscriptionData();
  }, []);

  // Fetch regions
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await api.get(`/region/availability?type=${selected}`);
        const regionsData: RegionData[] = response.data;
        setRegions(regionsData);
      } catch (error) {
        console.error("Failed to fetch region data:", error);
      }
    };

    fetchRegions();
  }, [selected]);

  // Initialize region input values
  useEffect(() => {
    if (!regions.length || !subscriptionData || initialValuesSet.current) {
      return;
    }

    const initialValues: Record<string, string> = {};

    regions.forEach((region: RegionData) => {
      const regionName = region.name;
      if (subscriptionData.regions && subscriptionData.regions[regionName]) {
        initialValues[regionName] =
          subscriptionData.regions[regionName].toString();
      } else {
        initialValues[regionName] = "";
      }
    });

    setRegionInputValues(initialValues);
    setRegionDistribution(initialValues);
    initialValuesSet.current = true;
  }, [regions, subscriptionData]);

  // Validate distribution
  useEffect(() => {
    if (count !== "") {
      validateDistribution(regionInputValues);
    } else {
      setRegionGlobalError("");
      setRegionErrors({});
      setHasDistributionError(false);
    }
  }, [count, regionInputValues, selected, regions]);

  const validateDistribution = (values: Record<string, string>) => {
    const newErrors: Record<string, string> = {};
    const totalDesired = parseInt(count) || 0;
    let currentTotal = 0;

    // Add minimum proxy validation
    const minProxies = selected === "shared" ? 20 : 10;
    if (totalDesired < minProxies) {
      setRegionGlobalError(
        `Minimum ${minProxies} proxies required for ${
          selected === "shared" ? "Hybrid" : "Singular"
        } mode.`
      );
      setHasDistributionError(true);
      return false;
    }

    regions.forEach((region) => {
      const regionCount = parseInt(values[region.name]) || 0;
      currentTotal += regionCount;

      if (regionCount > region.availability) {
        newErrors[
          region.name
        ] = `Exceeds available proxies (${region.availability})`;
      }
    });

    if (totalDesired === 0) {
      setRegionGlobalError("Please enter the number of proxies first");
    } else if (currentTotal !== totalDesired) {
      const diff = totalDesired - currentTotal;
      setRegionGlobalError(
        diff > 0
          ? `${diff} more ${diff === 1 ? "proxy" : "proxies"} needed`
          : `${Math.abs(diff)} extra ${
              Math.abs(diff) === 1 ? "proxy" : "proxies"
            } selected`
      );
    } else {
      setRegionGlobalError("");
    }

    setRegionErrors(newErrors);
    const hasError =
      Object.keys(newErrors).length > 0 || currentTotal !== totalDesired;
    setHasDistributionError(hasError);
    return !hasError;
  };
  const handleAvailabilityChange = (region: RegionData, value: string) => {
    if (value === "" || /^\d*$/.test(value)) {
      const newInputValues = {
        ...regionInputValues,
        [region.name]: value,
      };

      setRegionInputValues(newInputValues);
      setRegionDistribution(newInputValues);
      validateDistribution(newInputValues);
    }
  };

  const processedRegions = Object.entries(regionDistribution)
    .filter(([_, quantity]) => quantity !== "")
    .reduce((acc, [name, quantity]) => {
      const parsedQuantity = parseInt(quantity, 10);
      if (!isNaN(parsedQuantity)) {
        acc[name] = parsedQuantity;
      }
      return acc;
    }, {} as Record<string, number>);

  const handleTotalCountChange = (
    newCount: string | ((prev: string) => string)
  ) => {
    const updatedCount =
      typeof newCount === "function" ? newCount(count) : newCount;
    const numericCount = Number(updatedCount) || 0;
    const minProxies = selected === "shared" ? 50 : 25;

    setCount(updatedCount);
    setQuantity(numericCount);

    if (numericCount > 0 && numericCount < minProxies) {
      setRegionGlobalError(
        `Minimum ${minProxies} proxies required for ${
          selected === "shared" ? "Hybrid" : "Singular"
        } mode.`
      );
      setHasDistributionError(true);
    }
  };

  // Get unit price based on quantity from the appropriate tier
  const getUnitPrice = (quantity: number, pricingData: PricingData) => {
    const selectedPricingArray = pricingData[selected];
    if (!selectedPricingArray?.length) return 0;

    // Find the applicable tier based on quantity
    const applicableTier =
      selectedPricingArray.find(
        (tier) => tier.up_to === null || quantity <= tier.up_to
      ) || selectedPricingArray[selectedPricingArray.length - 1];

    return applicableTier.unit_amount;
  };

  // Calculate total price
  useEffect(() => {
    // Get the appropriate unit price based on quantity tier (in cents)
    const unitPrice = getUnitPrice(parseInt(count, 10) || 0, pricingData);
    const totalProxiesCount = parseInt(count, 10) || 0;

    // Calculate base price
    const basePrice = totalProxiesCount * unitPrice;

    // Add fixed usage charge if unlimited option is selected
    // Usage charge is flat 520 cents, not per proxy
    const usageCharge = 0;
    // usageOption === UsageOption.UNLIMITED
    // ? totalProxiesCount * unlimitedDataPricingPerMonth
    // : 0;

    // Calculate final price in dollars (converting from cents)
    const calculatedTotalPrice = (basePrice + usageCharge) / 100;

    if (firstPrice.current === null && calculatedTotalPrice !== 0) {
      //@ts-ignore
      firstPrice.current = calculatedTotalPrice;
    }
    setTotalPrice(calculatedTotalPrice);
  }, [count, priceId, usageOption, pricingData, selected]);

  return (
    <div
      className="h-screen grid grid-rows-[auto_1fr_auto]"
      style={{
        background: `linear-gradient(0deg, rgba(28, 27, 27, 0.50) 0%, rgba(28, 27, 27, 0.50) 100%), #212121`,
      }}
    >
      <Navbar />
      <div className="flex flex-col min-h-0 overflow-auto pl-[6.25rem] pr-[13.9375rem] w-full max-2xl:pl-[3.25rem] max-2xl:pr-[3.25rem] max-md:px-[1.875rem] max-sm:px-3">
        <div className="flex items-stretch w-full gap-[6rem] pt-[4.125rem] max-sm:pt-10 pb-6 max-2xl:gap-8 max-md:flex-col">
          <div className="w-full">
            {warning && <WarningOverlay />}
            <ProxyOption />
            <SharedDedicated selected={selected} setSelected={setSelected} />

            <ProxyCount
              pricingData={pricingData}
              setPricingData={setPricingData}
              selected={selected}
              setPriceId={setPriceId}
              count={count}
              setCount={handleTotalCountChange}
              setQuantity={setQuantity}
            />
            <UsageOptions
              pricing={unlimitedDataPricingPerMonth}
              setPricing={setUnlimitedDataPricingPerMonth}
              usageOption={usageOption}
              setUsageOption={setUsageOption}
            />
            <RegionSelector
              regions={regions}
              regionInputValues={regionInputValues}
              regionErrors={regionErrors}
              regionGlobalError={regionGlobalError}
              focusedRegion={focusedRegion}
              setFocusedRegion={setFocusedRegion}
              handleAvailabilityChange={handleAvailabilityChange}
            />
          </div>
          <div className="max-md:pb-4 max-sm:pb-0">
            <Checkout
              priceId={priceId}
              quantity={quantity}
              usage={usageOption}
              isError={hasDistributionError || !count}
              processedRegions={processedRegions}
              subscriptionData={subscriptionData}
              selected={selected}
              count={count}
              regionDistribution={regionDistribution}
              usageOption={usageOption}
              totalPrice={totalPrice}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
