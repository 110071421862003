import { useState, useRef, useEffect } from "react";
import { useApi } from "../../../api/api";

interface PriceTier {
  up_to: number;
  unit_amount: number;
  unit_amount_decimal: number | null;
  flat_amount: number;
  priceId: string;
}

export interface PricingData {
  shared: PriceTier[];
  dedicated: PriceTier[];
}

interface ProxyCountProps {
  pricingData: PricingData;
  setPricingData: React.Dispatch<React.SetStateAction<PricingData>>;
  selected: "shared" | "dedicated";
  setPriceId: (priceId: string) => void;
  count: string;
  setCount: (count: string) => void;
  setQuantity: (quantity: number) => void;
}
export const ProxyCount = ({
  selected,
  setPriceId,
  count,
  setCount,
  setQuantity,
  pricingData,
  setPricingData,
}: ProxyCountProps) => {
  const [inputWidth, setInputWidth] = useState(0);
  const [pricePerProxy, setPricePerProxy] = useState(0);

  const [priceTiers, setPriceTiers] = useState<PriceTier[]>([]);
  const api = useApi();

  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  // Function to handle input change and ensure only integers are entered
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Allow only numbers and limit input length
    if (/^\d{0,5}$/.test(value)) {
      // Example: Limit to 5 digits
      setCount(value);
    }
  };

  // Ensure width updates dynamically
  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth + 2);
    }
  }, [count]);

  // Adjust input width based on content
  useEffect(() => {
    setQuantity(Number(count));
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth + 2); // Add 2px padding
    }
  }, [count]);

  // Fetch pricing data from the API once when the component mounts
  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await api.get("/payment/pricing/isp");
        const data = response.data;

        // Process both Shared and Dedicated data
        const processedData: PricingData = { shared: [], dedicated: [] };

        ["shared", "dedicated"].forEach((key) => {
          const tiersArray = data[key].flat();
          const tiers: PriceTier[] = tiersArray.map((tier: any) => ({
            up_to: tier.up_to === null ? Infinity : Number(tier.up_to),
            unit_amount: Number(tier.unit_amount),
            flat_amount: Number(tier.flat_amount),
            priceId: tier?.priceId,
          }));
          processedData[key.toLowerCase() as keyof PricingData] = tiers;
        });

        setPricingData(processedData);
      } catch (error) {
        console.error("Failed to fetch pricing data:", error);
      }
    };

    fetchPricingData();
  }, []);

  // Update priceTiers whenever 'selected' changes
  useEffect(() => {
    if (pricingData[selected]) {
      setPriceTiers(pricingData[selected]);
      setPriceId(pricingData[selected][0]?.priceId);
    }
  }, [selected, pricingData]);

  // Set priceId when priceTiers change
  useEffect(() => {
    if (priceTiers.length > 0) {
      setPriceId(priceTiers[0]?.priceId);
    }
  }, [priceTiers, setPriceId]);

  // Update per-proxy price based on the input count and pricing tiers
  useEffect(() => {
    const numericCount = parseInt(count, 10) || 0;
    let newPricePerProxy = 0;

    if (priceTiers.length > 0) {
      for (const tier of priceTiers) {
        if (numericCount <= tier.up_to) {
          newPricePerProxy = tier.unit_amount / 100; // Convert cents to dollars
          setPriceId(tier.priceId); // Update priceId based on the tier
          break;
        }
      }
      // If count exceeds all tiers, use the last tier's price
      if (newPricePerProxy === 0) {
        const lastTier = priceTiers[priceTiers.length - 1];
        newPricePerProxy = lastTier.unit_amount / 100;
        setPriceId(lastTier.priceId); // Update priceId to the last tier's priceId
      }
      setPricePerProxy(newPricePerProxy);
    }
  }, [count, priceTiers, setPriceId]);

  return (
    <div className="mt-[2.375rem]">
      <h2 className="text-white text-xs font-medium font-[Roboto] mb-[0.625rem]">
        How many
      </h2>
      <div className="w-full flex items-center justify-between px-4 py-3 bg-[#272626] rounded-[0.1875rem] text-white hover:bg-[#323131] transition-colors">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="flex items-center mr-1"
            style={{ position: "relative" }}
          >
            <input
              ref={inputRef}
              placeholder="0"
              type="text"
              value={count}
              onChange={handleInputChange}
              className="text-[0.625rem] tracking-[0.0094rem] bg-transparent outline-none"
              style={{ width: inputWidth }}
            />
            <span
              ref={spanRef}
              className="invisible absolute whitespace-pre text-[0.625rem] inline-block tracking-[0.0094rem]"
            >
              {count || "0"}
            </span>
          </div>
          <span className="text-[0.625rem] tracking-[0.0094rem]"> Proxies</span>
        </div>
        <div className="flex items-center">
          <span className="mr-2 opacity-70 text-[0.625rem]">
            ${pricePerProxy.toFixed(4)}/Proxy
          </span>
        </div>
      </div>
    </div>
  );
};
